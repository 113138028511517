
.header__navigation {
    min-height: 40px;
    list-style-type: none;
    width: 60%;
    margin: -7px 0px 0 0px;
    padding: 0 0 0 10px;
    background: #004b6c;
    border-radius: 5px;
    //position: absolute;
    //left: pxToRem(32);
    //bottom: 0;
    transform: translateY(50%);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .5);
    font-weight: 300;
    flex: 1 1 100%;

    @include media-breakpoint-up(lg) {
        ul {
            display: flex;
            gap: pxToRem(16);
        }
        li.level-0 {
            position: relative;

            & > a {

                color: #fff;
                background: #004b6c;
                display: inline-block;
                padding: 9px 10px 5px 10px;
                margin: 0 6px;
                border-bottom: 3px solid #004b6c;
                &:hover {
                    //text-decoration: underline;
                    border-bottom: 3px solid #d6a900;
                }
            }

            &.active {
                & > a {
                    border-bottom: 3px solid #d6a900;
                }
            }

            &:hover {
                & > ul {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
        ul ul {
            display: block;
            position: absolute;
            z-index: 100;
            background: $color-primary;
            padding: 10px;
            top: 52px;
            opacity: 0;
            pointer-events: none;
            transition: all .6s ease;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0px;
                display: block;
                width: 100%;
                height: 30px;
                transform: translateY(-100%);
            }

        }
        li.level-1 {
            a {
                color: $color-white;
            }
        }
    }
}
.js-nav-scrolled .header__navigation {
    //position: fixed;
}
$red: #d33c25;
.korr-modus {
    .header__navigation{
        background: $red;

            li.level-0 > a {
                background: $red;
                border-bottom: 3px solid $red;
            }

    }
}
@include media-breakpoint-up(lg) {
    .header__navigation li.level-0.active > a {
        border-bottom: 3px solid #fff;
    }
}


.nav__user {
    position: absolute;
    right: 20px;
    top: 8px;
    color: #fff;
    ul {
        display: block;
        li:hover ul {
            opacity: 1;
            pointer-events: auto;
        }
        ul {
            display: block;
            position: absolute;
            background: #004b6c;
            z-index: 10;
            top: 40px;
            right: -20px;
            left: inherit;
            border-radius: 5px;

            &:hover {
                opacity: 1;
            }

            &:before {

                height: 40px;
                z-index: 1;
            }

            li {
                white-space: nowrap;
                width: 100%;
                a {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    padding-bottom: 2px !important;
                    :hover {
                        border-bottom: 1px !important;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .js-nav-open {
        overflow: hidden;

        .header__navigation {
            opacity: 1;
            pointer-events: auto;
        }
    }
    .header__navigation {
        background: $color-primary;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 200;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        overflow: auto;

        & > ul {
            margin-top: pxToRem(100);
            width: 100%;
        }

        li.level-0 {
            position: relative;
            padding: pxToRem(10) pxToRem(20);
            width: 100%;

            a {
                color: $color-white;
                display: block;
            }

            & > ul {
                padding-top: pxToRem(16);
            }
        }

        li.level-1 {
            padding: pxToRem(10) pxToRem(20);
        }

        .plus {
            position: absolute;
            right: 0;
            top: 7px;
            border: 1px solid $color-white;
            display: block;
            width: 30px;
            height: 30px;
            z-index: 10;

            &:after {
                content: '';
                display: flex;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                background-image: inline-svg('plus', $color-white);
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}


.burger {
    display: none;
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 600;
}

.header__logo--nav {
    display: none;

    a {
        color: #fff;
    }
}

.address--nav, .button.header-cta--nav {
    display: none;
}

@include media-breakpoint-down(lg) {
    .burger, .header__logo--nav, .address--nav, .button.header-cta--nav {
        display: block;
    }
}
