@import "@sx-core/src/scss/base/button.base.scss";

.button {
    border: 0;
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    color: #fff;
    background-color: #004b6c;
    border-color: #004b6c;
    border-radius: pxToRem(5);

    &:hover {
        background: #256c8b;
        text-decoration: none !important;

    }

    &:active {
    }

    &:focus {
    }

    &:disabled, &.disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}

.button--primary, .button--submit {
    background-color: #004b6c;
    //padding-right: pxToRem(25);


    &:hover {
        text-decoration: none !important;
        background: #256c8b;
    }

    &:active {
    }

    &:focus {
    }

    &:disabled, &.disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}

.button--secondary {
    background: $color-secondary;

    &:hover {
    }

    &:active {
    }

    &:focus {
    }

    &:disabled, &.disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}

.button--sm {

}

// ggf. inverted , outline , icon

.button-group {
    display: flex;
    gap: pxToRem(16);

    & > .button {
        flex: 0 0 auto;
    }
}

.cta {
    display: flex;
    gap: pxToRem(16);
    margin-top: pxToRem(28);
}
