//@use "sass:math" as math;
/**
 * Import media breakpoints and other shraed vars (js/css)
 */
@import 'shared-vars';
@import 'vars-figma-colors';

/**
 * Use autogenerated media in media mixins
 */
$media-steps: $sx-breakpoints;

$size-xxxs: pxToRem(8);
$size-xxs: pxToRem(16);
$size-xs: pxToRem(28);
$size-sm: pxToRem(40);
$size-md: pxToRem(48);
$size-lg: pxToRem(64);
$size-xl: pxToRem(96);
$size-xxl: pxToRem(128);
$size-xxxl: pxToRem(192);

$grid: (
    steps: (1, 12),
    gutters: (),
    breakpoint-gutters: (
        'xs': 10px,
        'sm': 16px,
        'md': 20px,
        'lg': 20px,
        'xl': 20px,
        'xxl': 20px,
    ),
    container-paddings: (
        'xs': 20px,
        'sm': 20px,
        'md': 25px,
        'lg': 25px,
        'xl': 25px,
        'xxl': 25px,
    ),
    container-paddings-fluid: (
        'xs': 20px,
        'sm': 20px,
        'md': 25px,
        'lg': 25px,
        'xl': 25px,
        'xxl': 25px,
    )
);

// bulid any combination of the numbers. Please reduce the numbers, if possible.
$image-ratio-steps: (
    1, 2, 3, 4, 5, 7, 9, 16, 18
);

$sx-path-images: '/dist/img/';


//comes from config/shared_vars.json

$header-height: $sx-headerheight;
$header-height-mobile: $sx-headerheight-mobile;


/**
 * Spacing vars
 */

$spacing-m: $size-xs; // TODO @zelli (?) aus der Filterbase bitte rausnehmen! Wenn wir das variabel haben wollen, dann lass uns doch eine $filter-spacing machen!


$modulspacing-desktop: 0;

$modulspacing-desktop-m: 0; // Standard

$modulspacing-mobile-m: 0; // Standard


/**
 * Color vars
 */

$color-primary: #003a63;
$color-primary-hover: scale_color($color-primary, $lightness: 30%) !default; /*Var for convenience*/

$color-secondary: $color-secondary-800;
$color-secondary-hover: scale_color($color-secondary, $lightness: 30%) !default; /*Var for convenience*/

$color-tertiary: #3FC1C9;
$color-tertiary-hover: scale_color($color-tertiary, $lightness: 30%) !default; /*Var for convenience*/

$color-success: $color-success-800;
$color-warning: $color-warning-800;
$color-danger: $color-error-800;
$color-info: #37C4EF;

$color-background: $color-grey-300;
$color-secondary-bg: #fff;
$color-primary-bg-hover: #fff;

/**
 * Font vars
 */

$sx-path-fonts: '/dist/font/';

$base-font-size: 16px;
$base-font-weight: 400;

$font-family-primary: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-secondary: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
//$font-family-tertiary: '---', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-color: $color-black; // definiert die Fliesstextfarbe!

$font-color-primary: $font-color;
$font-color-secondary: $color-black; // ggf. für Headline etc.
//$font-color-tertiary: $color-black;


/**
 * Border radius vars
 */

$border-radius: 0px !default;
$border-radius-button: 5px;