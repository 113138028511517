$color-black: rgba(0, 0, 0, 1);
$color-white: rgba(255, 255, 255, 1);
$color-muted-grey: rgba(245, 244, 245, 1);
$color-primary-100: rgba(244, 247, 243, 1);
$color-primary-200: rgba(239, 243, 238, 1);
$color-primary-300: rgba(223, 231, 220, 1);
$color-primary-400: rgba(206, 218, 201, 1);
$color-primary-500: rgba(186, 205, 179, 1);
$color-primary-600: rgba(165, 190, 154, 1);
$color-primary-700: rgba(140, 175, 125, 1);
$color-primary-800: rgba(111, 158, 87, 1);
$color-primary-900: rgba(102, 146, 80, 1);
$color-primary-1000: rgba(93, 133, 73, 1);
$color-primary-1100: rgba(83, 119, 65, 1);
$color-primary-1200: rgba(72, 103, 56, 1);
$color-primary-1300: rgba(59, 84, 46, 1);
$color-primary-1400: rgba(41, 59, 32, 1);
$color-secondary-100: rgba(242, 243, 242, 1);
$color-secondary-200: rgba(237, 237, 237, 1);
$color-secondary-300: rgba(217, 219, 218, 1);
$color-secondary-400: rgba(196, 199, 198, 1);
$color-secondary-500: rgba(172, 176, 175, 1);
$color-secondary-600: rgba(144, 151, 149, 1);
$color-secondary-700: rgba(109, 119, 116, 1);
$color-secondary-800: rgba(56, 77, 72, 1);
$color-secondary-900: rgba(51, 71, 66, 1);
$color-secondary-1000: rgba(47, 65, 60, 1);
$color-secondary-1100: rgba(42, 58, 54, 1);
$color-secondary-1200: rgba(36, 50, 47, 1);
$color-secondary-1300: rgba(29, 41, 38, 1);
$color-secondary-1400: rgba(21, 29, 27, 1);
$color-black-alpha-100: rgba(0, 0, 0, 0.050);
$color-black-alpha-200: rgba(0, 0, 0, 0.071);
$color-black-alpha-300: rgba(0, 0, 0, 0.143);
$color-black-alpha-400: rgba(0, 0, 0, 0.214);
$color-black-alpha-500: rgba(0, 0, 0, 0.286);
$color-black-alpha-600: rgba(0, 0, 0, 0.357);
$color-black-alpha-700: rgba(0, 0, 0, 0.429);
$color-black-alpha-800: rgba(0, 0, 0, 0.500);
$color-black-alpha-900: rgba(0, 0, 0, 0.571);
$color-black-alpha-1000: rgba(0, 0, 0, 0.643);
$color-black-alpha-1100: rgba(0, 0, 0, 0.714);
$color-black-alpha-1200: rgba(0, 0, 0, 0.786);
$color-black-alpha-1300: rgba(0, 0, 0, 0.857);
$color-black-alpha-1400: rgba(0, 0, 0, 0.929);
$color-white-alpha-100: rgba(255, 255, 255, 0.050);
$color-white-alpha-200: rgba(255, 255, 255, 0.071);
$color-white-alpha-300: rgba(254, 254, 254, 0.143);
$color-white-alpha-400: rgba(255, 255, 255, 0.214);
$color-white-alpha-500: rgba(255, 255, 255, 0.286);
$color-white-alpha-600: rgba(255, 255, 255, 0.357);
$color-white-alpha-700: rgba(254, 254, 254, 0.429);
$color-white-alpha-800: rgba(255, 255, 255, 0.500);
$color-white-alpha-900: rgba(255, 255, 255, 0.571);
$color-white-alpha-1000: rgba(255, 255, 255, 0.643);
$color-white-alpha-1100: rgba(255, 255, 255, 0.714);
$color-white-alpha-1200: rgba(255, 255, 255, 0.786);
$color-white-alpha-1300: rgba(255, 255, 255, 0.857);
$color-white-alpha-1400: rgba(255, 255, 255, 0.929);
$color-success-100: rgba(241, 248, 245, 1);
$color-success-200: rgba(236, 246, 241, 1);
$color-success-300: rgba(215, 237, 226, 1);
$color-success-400: rgba(193, 227, 210, 1);
$color-success-500: rgba(167, 217, 193, 1);
$color-success-600: rgba(137, 207, 174, 1);
$color-success-700: rgba(97, 196, 153, 1);
$color-success-800: rgba(16, 185, 129, 1);
$color-success-900: rgba(14, 171, 119, 1);
$color-success-1000: rgba(13, 156, 109, 1);
$color-success-1100: rgba(12, 139, 97, 1);
$color-success-1200: rgba(10, 121, 84, 1);
$color-success-1300: rgba(8, 98, 68, 1);
$color-success-1400: rgba(6, 69, 48, 1);
$color-warning-100: rgba(254, 247, 241, 1);
$color-warning-200: rgba(253, 243, 236, 1);
$color-warning-300: rgba(252, 231, 215, 1);
$color-warning-400: rgba(250, 218, 192, 1);
$color-warning-500: rgba(249, 205, 167, 1);
$color-warning-600: rgba(247, 190, 136, 1);
$color-warning-700: rgba(246, 175, 96, 1);
$color-warning-800: rgba(245, 158, 11, 1);
$color-warning-900: rgba(226, 146, 10, 1);
$color-warning-1000: rgba(207, 133, 9, 1);
$color-warning-1100: rgba(185, 119, 8, 1);
$color-warning-1200: rgba(160, 103, 7, 1);
$color-warning-1300: rgba(131, 84, 5, 1);
$color-warning-1400: rgba(92, 59, 4, 1);
$color-error-100: rgba(251, 235, 234, 1);
$color-error-200: rgba(249, 226, 225, 1);
$color-error-300: rgba(243, 197, 194, 1);
$color-error-400: rgba(237, 168, 164, 1);
$color-error-500: rgba(230, 139, 134, 1);
$color-error-600: rgba(224, 110, 104, 1);
$color-error-700: rgba(218, 81, 73, 1);
$color-error-800: rgba(212, 52, 43, 1);
$color-error-900: rgba(182, 45, 37, 1);
$color-error-1000: rgba(151, 37, 31, 1);
$color-error-1100: rgba(121, 30, 25, 1);
$color-error-1200: rgba(91, 22, 18, 1);
$color-error-1300: rgba(61, 15, 12, 1);
$color-error-1400: rgba(30, 7, 6, 1);
$color-grey-100: rgba(242, 242, 242, 1);
$color-grey-200: rgba(236, 236, 236, 1);
$color-grey-300: rgba(218, 218, 218, 1);
$color-grey-400: rgba(200, 200, 200, 1);
$color-grey-500: rgba(182, 182, 182, 1);
$color-grey-600: rgba(164, 164, 164, 1);
$color-grey-700: rgba(146, 146, 146, 1);
$color-grey-800: rgba(128, 128, 128, 1);
$color-grey-900: rgba(109, 109, 109, 1);
$color-grey-1000: rgba(91, 91, 91, 1);
$color-grey-1100: rgba(73, 73, 73, 1);
$color-grey-1200: rgba(54, 54, 54, 1);
$color-grey-1300: rgba(36, 36, 36, 1);
$color-grey-1400: rgba(18, 18, 18, 1);
$color-neutral-100: rgba(241, 242, 243, 1);
$color-neutral-200: rgba(235, 236, 238, 1);
$color-neutral-300: rgba(216, 218, 222, 1);
$color-neutral-400: rgba(196, 199, 205, 1);
$color-neutral-500: rgba(176, 180, 188, 1);
$color-neutral-600: rgba(156, 161, 171, 1);
$color-neutral-700: rgba(137, 143, 155, 1);
$color-neutral-800: rgba(117, 124, 138, 1);
$color-neutral-900: rgba(100, 106, 118, 1);
$color-neutral-1000: rgba(84, 89, 99, 1);
$color-neutral-1100: rgba(67, 71, 79, 1);
$color-neutral-1200: rgba(50, 53, 59, 1);
$color-neutral-1300: rgba(33, 35, 39, 1);
$color-neutral-1400: rgba(17, 18, 20, 1);