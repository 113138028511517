
.keyvisual {
    position: relative;
    overflow: hidden;
    height: auto;
    margin-top: $header-height;

    .image {
        position: unset; //reset
    }
    .image img {
        position: absolute; //reset
        height: 100%;
    }
}

.keyvisual--big {
    height: calc(100vh - $header-height);
}

.keyvisual-empty {
    height: $header-height;
}


@include media-breakpoint-down(md) {
    .keyvisual--big {
        // dieser Fall tritt vermutlich nie auf (siehe unten)
        height: calc(100vh - $header-height-mobile);
    }
    .keyvisual {
        margin-top: $header-height-mobile;
    }
}


@supports (height: 100svh) {
    @include media-breakpoint-down(md) {
        .keyvisual--big {
            height: calc(100vh - #{$header-height-mobile});
        }
    }
}
