.panel {

    color: #667580;
    margin-top: pxToRem(40);

}

.panel-body {
    overflow: scroll;
    background: #fff;
    margin-top: 20px;
}

.panel-heading {
    color: #fff;
    background-color: #004b6c;
    border-color: #004b6c;

    h4 {
        padding: pxToRem(10) pxToRem(10) pxToRem(10) pxToRem(10);
    }
}

.dt-column-title {
    color: #003a63;
    font-size: 16px;
    font-weight: 500;
}

.uk-table-hover > tr:hover, .uk-table-hover tbody tr:hover {
    background: #d8d8d8 !important;
}

.uk-table-striped > tr:nth-of-type(odd), .uk-table-striped tbody tr:nth-of-type(odd) {
    background: #e9e9e9;
}


div.dt-container select.dt-input {
    margin-right: pxToRem(5);
}

.dt-paging {
    border: 1px solid #abb3b7;
}

.panel-body {

    div.dt-container .dt-paging .dt-paging-button.current, div.dt-container .dt-paging .dt-paging-button.current:hover,
    div.dt-container .dt-paging .dt-paging-button:hover {
        color: #fff !important;
        background: #004b6c !important;
        border-color: #004b6c !important;
        cursor: default !important;
        border-radius: 0;
    }


    div.dt-container .dt-paging .dt-paging-button.disabled:hover, div.dt-container .dt-paging .dt-paging-button.disabled:active {
        color: #fff !important;
    }

    div.dt-container .dt-paging .dt-paging-button.disabled {
        margin: 0px;
    }
}

.panel-body {
    table.dataTable > tbody > tr.child span.dtr-title, table.dataTable>tbody>tr.child span.dtr-title {
        display: block;
        color: #45a1c5 !important;
        font-weight: 300;
        font-size: pxToRem(16);
        margin-top: pxToRem(12);
    }
    .table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
        font-size: 14px;
        font-weight: 300;
    }


}
div.dt-scroll, div.dtfh-floatingparent {
    //top: 55px !important;
}
.datatable-search {
    input, select {
        //max-width: 50px !important;
    }
}
td.all.datatable-action {
    //display: flex;
    //gap: 3px;
}
.first-col {
    max-width: 22px;
}
.child ul {
    margin-left: calc(22px + 2px);
}


table.dataTable tbody tr{
    background: #fff;
    &:nth-child(odd) {

        & >.dtfc-fixed-start, & tr>.dtfc-fixed-end {
            background: #e9e9e9;
        }
        &:hover {
            & >.dtfc-fixed-start, & tr>.dtfc-fixed-end {
                background: #d8d8d8;
            }
        }
    }
    &:nth-child(even) {


        &:hover {
            & >.dtfc-fixed-start, & tr>.dtfc-fixed-end {
                background: #d8d8d8;
            }
        }
    }
}
div.dt-scroll, div.dtfh-floatingparent {
    z-index: 2;
    //overflow: visible !important
}
.dtfh-floatingparent.dtfh-floatingparent-head {
    //&:after {
    //    content: '';
    //    width: 25px;
    //    height: 100%;
    //    display: block;
    //    position: absolute;
    //    right: 0;
    //    top: 0;
    //    background: white;
    //    transform: translateX(100%);
    //}

}


.dataTable td a {
    text-decoration: underline;
    text-decoration-color: #d6a900;
    text-underline-offset: 2px;
}

.dt-scroll-head, .dtfh-floatingparent-head {
    .input-group > .button, .input-group > .input-group-append, .input-group > .input-group-prepend, .input-group > .input, .input-group > .input-group-append, .input-group > .input-group-prepend {
        margin-left: 0;
        font-size: 14px;
    }
    .filtertype {
        transform: translateX(-2px);
        background: white;
    }
    .input-group-prepend {
        background: #fff;
    }
    .sx-cancel-1{
        transform: rotate(-45deg) translate(-3px,-12px);
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #d6a900;
        width: 16px;
        height: 16px;
        position: absolute;
        right: -18px;
        top: 0;
    }
}



.panel-body div.dt-container .dt-paging .dt-paging-button.current, .panel-body div.dt-container .dt-paging .dt-paging-button.current:hover, .panel-body div.dt-container .dt-paging .dt-paging-button:hover {
    &:not(.disabled) {
    cursor: pointer !important;
    }
}

dt {
    width: 200px;
    font-weight: bold;
    display: inline-block;
    margin-right: 0px ;
    margin-bottom: 7px;
}

dd {
    display: inline;
    margin-bottom: 20px;
}

dd:after {
    content: "";
    display: block;
    clear: both;
}
div.dt-processing>div:last-child>div {
    background: $color-white !important;
}
select.filtertype {
    -webkit-appearance: none;
    appearance: none;
    min-width: 28px;
    //margin-left: -6px;
    padding-left: 13px;
    background: transparent !important;
    width: 100%;
}

.input-group-prepend:has(select.filtertype ) {
    background: #dddddd;
    min-width: inherit !important;
}

input[type=date] {
    max-width: 120px !important;
    width: 120px !important;
    min-width: inherit !important;
}
.dt-scroll-head .input-group > .button, .dt-scroll-head .input-group > .input-group-append, .dt-scroll-head .input-group > .input-group-prepend, .dt-scroll-head .input-group > .input, .dt-scroll-head .input-group > .input-group-append, .dt-scroll-head .input-group > .input-group-prepend,
.input-group > .button:first-child, .input-group > .input-group-append:first-child > .button, .input-group > .input-group-prepend:first-child > .button, .input-group > .input:first-child, .input-group > .input-group-append:first-child > .input, .input-group > .input-group-prepend:first-child > .input{
    min-width: 100%;
}

// -------------


.panel-primary {
    border-color: #004b6c
}

.panel-primary .panel-heading {
    color: #fff;
    background-color: #004b6c;
    border-color: #004b6c;


}

.panel-primary .panel-body {
    border-top: 2px solid #004b6c
}

.panel-success {
    border-color: #85c744
}

.panel-success .panel-heading {
    color: #fff;
    background-color: #85c744;
    border-color: #85c744
}

.panel-success .panel-body {
    border-top: 2px solid #85c744
}

.panel-warning {
    border-color: #f1c40f
}

.panel-warning .panel-heading {
    color: #fff;
    background-color: #f1c40f;
    border-color: #f1c40f
}

.panel-warning .panel-body {
    border-top: 2px solid #f1c40f
}

.panel-danger {
    border-color: #e73c3c
}

.panel-danger .panel-heading {
    color: #fff;
    background-color: #e73c3c;
    border-color: #e73c3c
}

.panel-danger .panel-body {
    border-top: 2px solid #e73c3c
}

.panel-info {
    border-color: #2bbce0
}

.panel-info .panel-heading {
    color: #fff;
    background-color: #2bbce0;
    border-color: #2bbce0
}

.panel-info .panel-body {
    border-top: 2px solid #2bbce0
}

.panel-inverse {
    border-color: #4f5259
}

.panel-inverse .panel-heading {
    color: #fff;
    background-color: #4f5259;
    border-color: #4f5259
}

.panel-inverse .panel-body {
    border-top: 2px solid #4f5259
}

.panel-brown {
    border-color: #c0392b
}

.panel-brown .panel-heading {
    color: #fff;
    background-color: #c0392b;
    border-color: #c0392b
}

.panel-brown .panel-body {
    border-top: 2px solid #c0392b
}

.panel-indigo {
    border-color: #9358ac
}

.panel-indigo .panel-heading {
    color: #fff;
    background-color: #9358ac;
    border-color: #9358ac
}

.panel-indigo .panel-body {
    border-top: 2px solid #9358ac
}

.panel-orange {
    border-color: #efa131
}

.panel-orange .panel-heading {
    color: #fff;
    background-color: #efa131;
    border-color: #efa131
}

.panel-orange .panel-body {
    border-top: 2px solid #efa131
}

.panel-sky {
    border-color: #76c4ed
}

.panel-sky .panel-heading {
    color: #fff;
    background-color: #76c4ed;
    border-color: #76c4ed
}

.panel-sky .panel-body {
    border-top: 2px solid #76c4ed
}

.panel-midnightblue {
    border-color: #34495e
}

.panel-midnightblue .panel-heading {
    color: #fff;
    background-color: #34495e;
    border-color: #34495e
}

.panel-midnightblue .panel-body {
    border-top: 2px solid #34495e
}

.panel-magenta {
    border-color: #e73c68
}

.panel-magenta .panel-heading {
    color: #fff;
    background-color: #e73c68;
    border-color: #e73c68
}

.panel-magenta .panel-body {
    border-top: 2px solid #e73c68
}

.panel-green {
    border-color: #16a085
}

.panel-green .panel-heading {
    color: #fff;
    background-color: #16a085;
    border-color: #16a085
}

.panel-green .panel-body {
    border-top: 2px solid #16a085
}

.panel-purple {
    border-color: #e044ab
}

.panel-purple .panel-heading {
    color: #fff;
    background-color: #e044ab;
    border-color: #e044ab
}

.panel-purple .panel-body {
    border-top: 2px solid #e044ab
}

.panel-grape {
    border-color: #7a869c
}

.panel-grape .panel-heading {
    color: #fff;
    background-color: #7a869c;
    border-color: #7a869c
}

.panel-grape .panel-body {
    border-top: 2px solid #7a869c
}

.panel-red {
    border-color: #004b6c
}

.panel-red .panel-heading {
    color: #fff;
    background-color: #004b6c;
    border-color: #004b6c
}

.panel-red .panel-body {
    border-top: 2px solid #004b6c
}

.panel.gray {
    border-color: #000
}

.panel.gray .panel-heading {
    color: #cbcac3;
    background-color: #edeef0;
    border-color: #000
}

.panel.gray .panel-body {
    border-top: 2px solid #000
}

.panel.gray .panel-heading {
    border-bottom: 1px solid #d2d3d6;
    padding-right: 10px
}

.panel.gray .panel-heading .nav.nav-tabs li.active a {
    background: #fff
}

.panel.gray .panel-heading .nav.nav-tabs li.active a:hover {
    background: #fff
}

.panel.gray .panel-heading h4 {
    padding-left: 10px;
    color: #000 !important
}

.panel.gray .panel-heading h4 > .nav.nav-tabs {
    right: 10px;
    bottom: -3px
}

.panel.gray .panel-heading h4 > .nav.nav-tabs li a {
    line-height: 38px;
    padding: 0 10px;
    margin-top: -2px
}

.panel.gray .panel-body {
    background-color: #fff;
    border-top: 0px !important;
    border-left: 1px solid #d2d3d6;
    border-right: 1px solid #d2d3d6;
    border-bottom: 1px solid #d2d3d6;
    padding: 20px !important
}

.panel-gray {
    border-color: #edeef0
}

.panel-gray .panel-heading {
    color: #000;
    background-color: #edeef0;
    border-color: #edeef0
}

.panel-gray .panel-body {
    border-top: 2px solid #edeef0
}

.panel-gray .panel-heading {
    border-top: 1px solid #d2d3d6 !important;
    border-left: 1px solid #d2d3d6 !important;
    border-right: 1px solid #d2d3d6 !important;
    border-bottom: none
}

.panel-gray .panel-heading h4 {
    color: #000 !important
}

.panel-gray .panel-heading .options a {
    color: #000 !important
}

.panel-gray .panel-heading.rounded-bottom {
    border-bottom: 1px solid #d2d3d6 !important
}

.panel-gray .panel-body {
    border-bottom: 1px solid #d2d3d6 !important;
    border-left: 1px solid #d2d3d6 !important;
    border-right: 1px solid #d2d3d6 !important
}

[class*="panel-"].panel .panel-heading {
    padding: 0 10px;
    border-bottom: none
}

[class*="panel-"].panel .panel-heading.rounded-bottom {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

[class*="panel-"].panel .panel-heading .nav.nav-tabs > li a {
    color: #fff;
    color: rgba(255, 255, 255, 0.75)
}

[class*="panel-"].panel .panel-heading .nav.nav-tabs > li a:hover {
    color: #fff
}

[class*="panel-"].panel .panel-heading .nav.nav-tabs > li.active a {
    color: #000;
    background: #fff
}

[class*="panel-"].panel .panel-heading .options a {
    color: #fff
}

[class*="panel-"].panel .panel-heading .options .nav-tabs li, [class*="panel-"].panel .panel-heading .options .nav-tabs li a {
    border: none;
    font-size: 14px !important
}

[class*="panel-"].panel .panel-heading .options .nav-tabs li a {
    line-height: 35px
}

[class*="panel-"].panel .panel-heading .options .nav-tabs li.active a:hover {
    color: #000
}

[class*="panel-"].panel .panel-heading .options .nav-tabs li a i {
    font-size: 14px
}

[class*="panel-"].panel .panel-body {
    padding: 14px 20px 17px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff
}

[class*="panel-"].panel .panel-footer {
    background-color: #f7f8fa;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #ddd;
    padding: 20px 20px;
    color: #000000
}

[class*="panel-"].panel .panel-heading + .panel-body {
    border-top: none
}

[class*="panel-"].panel .panel-body .panel-footer {
    margin: 20px -21px -21px;
    border-top: 1px solid #ddd
}

.panel-heading > h4 > ul.nav.nav-tabs {
    position: relative;
    bottom: -3px
}

.panel-heading > h4 > ul.nav.nav-tabs > li {
    padding-left: 0
}

.panel-heading > h4 > ul.nav.nav-tabs > li > a {
    padding: 0 10px;
    line-height: 38px;
    margin-top: -2px
}

[class*="panel-"].panel .panel-heading > h4 {
    color: #fff
}

[class*="panel-"].panel .panel-heading > h4 > ul.nav.nav-tabs {
    bottom: -1px;
    right: 9px;
    border: none
}

[class*="panel-"].panel .panel-heading > h4 > ul.nav.nav-tabs > li > a {
    border: none;
    padding: 0 10px;
    line-height: 40px;
    margin-top: 0px
}

.panel-body > .list-group {
    margin: -20px
}

.panel-body > .list-group .list-group-item {
    border-width: 1px 0
}

.panel-body > .list-group .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.panel-body > .list-group .list-group-item:last-child {
    border-bottom: 0
}

.panel-heading + .list-group .list-group-item:first-child {
    border-top-width: 0
}

.panel > .table {
    margin-bottom: 0
}

.panel > .panel-body + .table {
    border-top: 1px solid #e6e7e8
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 0;
    overflow: hidden
}

.panel-group .panel + .panel {
    margin-top: 10px
}

.panel-group .panel-heading {
    border-bottom: 0
}

.panel-group .panel-heading h4 a {
    color: #fff
}

.panel-group .panel-heading + .panel-collapse .panel-body {
    border-top: 1px solid #ddd
}

.panel-group .panel-footer {
    border-top: 0
}

.panel-group .panel-footer + .panel-collapse .panel-body {
    border-bottom: 1px solid #ddd
}

.panel-group .panel-collapse .panel-body {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.panel .panel-heading .nav.nav-tabs > li {
    margin-bottom: -2px
}

.panel .panel-heading .nav.nav-tabs > li.dropdown > a:hover, .panel .panel-heading .nav.nav-tabs > li.dropdown a:focus {
    color: #000;
    background-color: transparent
}

.panel .panel-heading .nav.nav-tabs > li.dropdown.open > a, .panel .panel-heading .nav.nav-tabs > li.dropdown.open > a:hover, .panel .panel-heading .nav.nav-tabs > li.dropdown.open > a:focus {
    color: #000;
    background-color: transparent;
    border: 1px solid transparent
}

[class*="panel-"].panel .panel-heading .nav.nav-tabs > li {
    margin-bottom: -1px
}

[class*="panel-"].panel .panel-heading .nav.nav-tabs > li.dropdown > a:hover, [class*="panel-"].panel .panel-heading .nav.nav-tabs > li.dropdown a:focus {
    background-color: transparent;
    color: #fff;
    color: rgba(255, 255, 255, 0.75)
}

[class*="panel-"].panel .panel-heading .nav.nav-tabs > li.dropdown.open > a, [class*="panel-"].panel .panel-heading .nav.nav-tabs > li.dropdown.open > a:hover, [class*="panel-"].panel .panel-heading .nav.nav-tabs > li.dropdown.open > a:focus {
    background-color: transparent;
    color: #fff;
    border: none
}

.panel-heading h4 .nav.nav-tabs .dropdown-menu {
    margin-top: -2px
}

.panel-heading .nav.nav-tabs > li.dropdown.open ul.dropdown-menu li a {
    color: #000
}

.panel-heading .nav.nav-tabs > li.dropdown.open ul.dropdown-menu li a:hover {
    background-color: #D2D3D6;
    border: none
}

.panel-comments, .panel-threads, .panel-users {
    padding-left: 0;
    list-style: none;
    margin-bottom: 20px
}

.panel-comments li, .panel-threads li, .panel-users li {
    display: block;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px;
    border-bottom: 1px solid #e6e7e8
}

.panel-comments li:before, .panel-threads li:before, .panel-users li:before, .panel-comments li:after, .panel-threads li:after, .panel-users li:after {
    content: " ";
    display: table
}

.panel-comments li:after, .panel-threads li:after, .panel-users li:after {
    clear: both
}

.panel-comments li:before, .panel-threads li:before, .panel-users li:before, .panel-comments li:after, .panel-threads li:after, .panel-users li:after {
    content: " ";
    display: table
}

.panel-comments li:after, .panel-threads li:after, .panel-users li:after {
    clear: both
}

.panel-comments li:first-child, .panel-threads li:first-child, .panel-users li:first-child {
    padding-top: 0
}

.panel-comments li img, .panel-threads li img, .panel-users li img {
    float: left;
    height: 30px;
    width: 30px;
    border-radius: 1px
}

.panel-comments li .content, .panel-threads li .content, .panel-users li .content {
    margin-left: 45px
}

.panel-comments li .content .time, .panel-threads li .content .time, .panel-users li .content .time {
    float: right;
    color: #aeafb1;
    font-size: 12px
}

.panel-userprofile {
    padding-left: 0;
    list-style: none;
    margin-top: 20px;
    margin-bottom: 20px
}

.panel-userprofile li {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #e6e7e8
}

.panel-userprofile li:before, .panel-userprofile li:after {
    content: " ";
    display: table
}

.panel-userprofile li:after {
    clear: both
}

.panel-userprofile li:before, .panel-userprofile li:after {
    content: " ";
    display: table
}

.panel-userprofile li:after {
    clear: both
}

.panel-userprofile li:first-child {
    padding-top: 0
}

.panel-userprofile li:last-child {
    border-bottom: 0
}

.panel-userprofile li img {
    float: left;
    height: 30px;
    width: 30px;
    border-radius: 1px
}

.panel-userprofile li .content {
    margin-left: 45px
}

.panel-userprofile li .content .time {
    float: right;
    color: #aeafb1;
    font-size: 12px
}

.panel-comments li .content .commented {
    display: block;
    margin-bottom: 10px
}

.panel-comments li .content .actions {
    display: block;
    line-height: 1em;
    float: right
}

.panel-comments li .content .actions a {
    color: #4f5259;
    font-size: 14px;
    margin-left: 10px
}

.panel-comments li .content .actions a:first-child {
    margin-left: 0
}

.panel-comments li .content .actions a:hover {
    text-decoration: none;
    color: #cbcac3
}

.panel-threads li .content .title {
    display: block
}

.panel-threads li .content .thread {
    color: #aeafb1;
    display: block;
    font-size: 12px;
    padding-top: 5px;
    line-height: 14px
}

.panel-threads li .content .thread a {
    color: #4f5259
}

.panel-threads li .content .thread a:hover {
    color: #cbcac3;
    text-decoration: none
}

.panel-users li .content .desc {
    display: block
}

.panel-users li .content > div {
    font-size: 12px;
    color: #aeafb1
}

.panel-tasks {
    padding-left: 0;
    list-style: none;
    margin-bottom: 20px
}

.panel-tasks li {
    display: block;
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px 10px;
    background-color: #f2f3f5;
    margin-bottom: 1px;
    border-left: 2px solid #e6e7e8;
    border-radius: 1px
}

.panel-tasks li:before, .panel-tasks li:after {
    content: " ";
    display: table
}

.panel-tasks li:after {
    clear: both
}

.panel-tasks li:before, .panel-tasks li:after {
    content: " ";
    display: table
}

.panel-tasks li:after {
    clear: both
}

.panel-tasks li label {
    margin-bottom: 0
}

.panel-tasks li label .task-description {
    margin: 0 5px 0 5px
}

.panel-tasks li label .task-description.done {
    text-decoration: line-through;
    font-style: italic;
    opacity: 0.8;
    filter: alpha(opacity=80)
}

.panel-tasks li.item-primary {
    border-left: 2px solid #004b6c
}

.panel-tasks li.item-info {
    border-left: 2px solid #2bbce0
}

.panel-tasks li.item-danger {
    border-left: 2px solid #e73c3c
}

.panel-tasks li.item-warning {
    border-left: 2px solid #f1c40f
}

.panel-tasks li.item-success {
    border-left: 2px solid #85c744
}

.panel-tasks li.item-inverse {
    border-left: 2px solid #4f5259
}

.panel-tasks li.item-placeholder {
    background-color: #e6e7e8;
    border: 1px dashed #666;
    height: 42px
}

.panel-tasks li .options {
    float: right
}

.panel-tasks li .icon-dragtask {
    color: transparent;
    margin-right: 6px;
    width: 6px;
    height: 14px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAOCAMAAAA7QZ0XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFf39///F3PnHQAAAAJ0Uk5T/wDltzBKAAAAGklEQVR42mJgYGBkZAABMMUIBjCKKnIAAQYAClYANToXblMAAAAASUVORK5CYII=);
    cursor: -webkit-grab;
    cursor: -moz-grab
}

.panel-tasks li .icon-dragtask:active, .panel-tasks li .icon-dragtask:focus {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing
}

.panel-chat {
    margin-bottom: 20px;
    height: 700px;
    overflow-y: auto
}

.panel-chat .chat-message {
    margin-bottom: 10px;
    position: relative
}

.panel-chat .chat-message:last-child {
    margin-bottom: 0
}

.panel-chat .chat-message:after {
    left: 38px;
    top: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #EEE;
    border-width: 6px;
    margin-top: -4px
}

.panel-chat .chat-message .chat-contact {
    float: left;
    margin-right: 10px
}

.panel-chat .chat-message .chat-contact img {
    max-width: 40px;
    border-radius: 1px
}

.panel-chat .chat-message .chat-text {
    padding: 10px;
    background-color: #eee;
    overflow: hidden;
    position: relative
}

.panel-chat .chat-message.me .chat-contact {
    float: right;
    margin-left: 10px;
    margin-right: 0px
}

.panel-chat .chat-message.me .chat-text {
    background-color: #ddd
}

.panel-chat .chat-message.me:after {
    border-right-color: transparent;
    border-left-color: #ddd;
    right: 38px;
    left: auto
}
table.dataTable>thead>tr>th, table.dataTable>thead>tr>td{
    border-bottom: 1px solid   #abb3b7 !important;
}

.chosen-pseudo, .chosen-container .chosen-single, .chosen-container .chosen-choices,
.checkbox > label input[type=checkbox]{
    border: 1px solid   #abb3b7 !important;
}
label[for=korrekturmodus-checkbox] {
    position: absolute;
    top: pxToRem(62);
    right: pxToRem(20);

}


.data-editable {
    display: flex;
    gap:3px;
}

.dt-processing {
    background: rgba(0, 0, 0, .5);
    color: #fff !important;
    padding-top: 12px !important;
    border-radius: 4px;
}

.view-formular form {
    padding: pxToRem(60px) 0;
}