.ico {


    position: relative;

    &:before {
        content: '';
        display: block;
        //position: absolute;
        width: 20px;
        height: 20px;
        margin-right: 1px;
        background-position: center center;
        background-repeat: no-repeat;

    }
}

.button {
    .ico {
        padding-left: pxToRem(28);
        &:before {
            position: absolute;
            left: 0;
            top: 1px;
        }
    }
}

.ico--firmenkunde {
    &:before {
        background-image: inline-svg('ico-firmenkunde', transparent, transparent, #000000);
    }
}
.ico--delete {
    &:before {
        background-image: inline-svg('ico-delete', transparent, transparent, #000000);
    }
}

.ico--firmenkunde--white {
    &:before {
        background-image: inline-svg('ico-firmenkunde', transparent, transparent, #ffffff);
    }
}

.ico--firmenkunde-edit {
    &:before {
        background-image: inline-svg('ico-firmenkunde-edit', transparent, transparent, #000000);
    }
}

.ico--firmenkunde-edit--white {
    &:before {
        background-image: inline-svg('ico-firmenkunde-edit', transparent, transparent, #ffffff);
    }
}

.ico--versicherungsnehmer {
    &:before {
        background-image: inline-svg('ico-versicherungsnehmer', transparent, transparent, #000000);
    }
}

.ico--versicherungsnehmer--white {
    &:before {
        background-image: inline-svg('ico-versicherungsnehmer', transparent, transparent, #ffffff);
    }
}


.ico--versicherungsnehmer-neu {
    &:before {
        background-image: inline-svg('ico-versicherungsnehmer-neu', transparent, transparent, #000000);
    }
}

.ico--versicherungsnehmer-neu--white {
    &:before {
        background-image: inline-svg('ico-versicherungsnehmer-neu', transparent, transparent, #ffffff);
    }
}

.ico--versicherungsnehmer-edit {
    &:before {
        background-image: inline-svg('ico-versicherungsnehmer-edit', transparent, transparent, #000000);
    }
}

.ico--versicherungsnehmer-edit--white {
    &:before {
        background-image: inline-svg('ico-versicherungsnehmer-edit', transparent, transparent, #ffffff);
    }
}

.ico--vertrag {
    &:before {
        background-image: inline-svg('ico-vertrag', transparent, transparent, #000000);
    }
}

.ico--versicherungsnehmer-edit--white {
    &:before {
        background-image: inline-svg('ico-versicherungsnehmer-edit', transparent, transparent, #ffffff);
    }
}

.ico--vertrag-edit {
    &:before {
        background-image: inline-svg('ico-vertrag-edit', transparent, transparent, #000000);
    }
}

.ico--vertrag-edit--white {
    &:before {
        background-image: inline-svg('ico-vertrag-edit', transparent, transparent, #ffffff);
    }
}

.ico--vertrag-new {
    &:before {
        background-image: inline-svg('ico-vertrag-new', transparent, transparent, #000000);
    }
}

.ico--vertrag-new--white {
    &:before {
        background-image: inline-svg('ico-vertrag-new', transparent, transparent, #ffffff);
    }
}

.ico--login-new {
    &:before {
        background-image: inline-svg('ico-login-new', transparent, transparent, #000000);
    }
}

.ico--login-new--white {
    &:before {
        background-image: inline-svg('ico-login-new', transparent, transparent, #ffffff);
    }
}

.ico--login {
    &:before {
        background-image: inline-svg('ico-login', transparent, transparent, #000000);
    }
}

.ico--login--white {
    &:before {
        background-image: inline-svg('ico-login', transparent, transparent, #ffffff);
    }
}

.ico--new {
    &:before {
        background-image: inline-svg('ico-new', transparent, transparent, #000000);
    }
}

.ico--new--white {
    &:before {
        background-image: inline-svg('ico-new', transparent, transparent, #ffffff);
    }
}

.ico--new {
    &:before {
        background-image: inline-svg('ico-new', transparent, transparent, #000000);
    }
}

.ico--new--white {
    &:before {
        background-image: inline-svg('ico-new', transparent, transparent, #ffffff);
    }
}

.ico--bearbeiten {
    &:before {
        background-image: inline-svg('ico-bearbeiten', transparent, transparent, #000000);
    }
}

.ico--bearbeiten--white {
    &:before {
        background-image: inline-svg('ico-bearbeiten', transparent, transparent, #ffffff);
    }
}

