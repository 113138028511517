/*** Warenkorb Header ***/

.header__cart {
    position: relative;
    display: flex;
    align-items: flex-end;

    a {
        flex: 1 1 auto;
    }

    [data-shop] {
        position: relative;
        display: flex;
        width: 36px;
        height: 36px;
        background-image: inline-svg('shopping-bag', $color-secondary, transparent);
        background-position: center;
        background-size: 20px;
        background-repeat: no-repeat;
        border: 1px solid $color-primary;
        border-radius: 50%;
        transition: all .3s ease;
    }


    .cart-counter__badge {
        position: absolute;
        right: -12px;
        top: -8px;
        display: inline-block;
        color: $color-white;
        background: $color-primary;
        font-size: 12px;
        font-weight: 700;
        padding: 2px 8px;
        border-radius: 500px;
    }

    [data-cart-index="0"] [data-cart-shift] {
        display: none;
    }
}


@include media-breakpoint-up(lg) {
    .header__cart [data-shop] {
        &:hover, &:focus {
            background-color: $color-secondary;
            background-image: inline-svg('shopping-bag', $color-white, transparent);
            border-color: $color-secondary;
        }
    }
}

/*** Warenkorb ***/

.cart--empty {

}

.cart-view, .checkout-view {
    padding-top: pxToRem(96); //raus
    padding-bottom: pxToRem(96); //raus

    h3, h1 {
        font-size: pxToRem(26); //raus
        margin-bottom: pxToRem(26); //raus
    }

    .formular.modulspacing {
        margin: 0;
    }

}

.cart__frame {
    padding: pxToRem(20); //raus
    background-color: $color-background;
}

/*** Warenkorb ***/
.cart__summary {

    .summary__brutto, .summary__shipping, .summary__total, .summary__vat {
        margin-bottom: pxToRem(8); //raus
    }

    .summary__brutto, .summary__shipping, .summary__total {
        display: flex;
        justify-content: space-between;

        .summary__label {
            hyphens: auto;
        }

        .summary__value {
            padding-left: pxToRem(8);
            white-space: nowrap;
        }
    }

    .summary__vat {
        overflow-wrap: break-word;
    }

    .summary__total {
        padding-top: pxToRem(20);
        margin-top: pxToRem(20);
        font-weight: 700;
        border-top: 1px solid $color-black;
    }

    .button--checkout {

    }


}


.cart__coupon {

    input {
        width: 100%;
    }

    .button {
        width: 100%;
        margin-top: pxToRem(20);
    }
}

/*** Cart View ***/

.cart-item {
    background-color: $color-primary-light;

    &.out-of-stock {
        opacity: 0.6;
    }

    &:not(:last-child) {
        margin-bottom: pxToRem(60);
    }
}

.cart-item__headline {
    display: flex;
    background-color: $color-primary;

    span {
        display: inline-flex;
        align-items: center;
        padding: pxToRem(8) pxToRem(18);
        color: $color-white;
        font-weight: 700;
        line-height: 1.2;
        background-color: $color-secondary;
    }
}

.cart-item__content {
    display: flex;
    justify-content: space-between;
    column-gap: pxToRem(20);
    padding: pxToRem(28) pxToRem(18);

    &--left {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &--right {
        display: flex;
        flex-direction: column;
    }
}

.cart-item__custom {
    display: flex;
    font-weight: 700;

    &__label {
        min-width: pxToRem(140);
    }

    &:not(:first-child) {
        padding-top: pxToRem(16);
        margin-top: pxToRem(16);
        border-top: 1px solid $color-primary;
    }
}

.cart-item__actions {
    margin-top: auto;
    padding-top: pxToRem(36);
}

.cart-item__action {
    position: relative;
    padding-left: pxToRem(32);
    font-size: pxToRem(15);

    &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: 0;
        display: inline-block;
        width: 24px;
        height: 24px;
        background-position: center;
        background-size: 24px;
        background-repeat: no-repeat;
    }

    &--remove {
        &::before {
            background-image: inline-svg('trash-bin', $color-primary, transparent);
        }
    }

    &--update {
        &::before {
            background-image: inline-svg('edit', $color-primary, transparent);
        }
    }

    &:not(:first-child) {
        margin-left: pxToRem(25);

        &::after {
            content: '';
            position: absolute;
            top: -3px;
            left: -13px;
            width: 1px;
            height: 26px;
            background-color: #8C9BAB;
        }
    }
}

.cart-item__quantity {
    margin-bottom: pxToRem(48);

    .bootstrap-touchspin {
        display: flex;
        align-items: center;
        height: pxToRem(48);
        border: 1px solid $color-primary;
        border-radius: 36px;

        & > span {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-color: transparent;

            .button {
                width: 40px;
                height: 40px;
                color: $color-secondary;
                font-weight: 400;
                font-size: pxToRem(20);
                background-color: transparent;
                border-color: transparent;

                &:hover, &:focus {
                    background-color: transparent;
                }
            }

            .button::after {
                content: none;
            }
        }

        .input {
            width: 50px;
            text-align: center;
            font-weight: 700;
            background-color: transparent;
            border: none;
        }
    }
}

.cart-item__total {
    margin-top: auto;
}

.cart-item__value {
    text-align: right;
    font-size: pxToRem(22);
    font-weight: 700;
}

.cart-item__price {
    margin-top: pxToRem(4);
    font-size: pxToRem(15);
    text-align: right;
}


@include media-breakpoint-down(md) {
    .cart {
        padding-top: pxToRem(72);
        padding-bottom: pxToRem(72);
    }
}

@include media-breakpoint-down(sm) {
    .cart__content {
        flex-direction: column;

        &--left {
            flex: 1 1 100%;
        }

        &--right {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: pxToRem(20);
        }
    }

    .cart-item__quantity {
        margin-bottom: initial;
    }

    .cart-item__actions {
        padding-top: initial;
        margin-top: pxToRem(12);
    }
}