
.header {
    position: absolute;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: $header-height;
    background: #fff;
    border-bottom: 1px solid #cbcac3;

}

.header__logo {
    img {
        width: auto !important;
        height: 68px !important;
        max-width: inherit !important;
    }
}

.header > .container,
.header > .container > .grid {
    height: 100%;
}

.header__logo {
    width: pxToRem(200);
    margin-top: pxToRem(13);
}

@include media-breakpoint-up(lg) {
    .js-nav-scrolled {
        .header {
            //transform: translateY(-100%);
            position: fixed;
            height: 34px;
        }
        .header__logo {
            display: none;
        }
    }
}

.js-nav-scrolled02.scroll-up .header {
    //transform: translateY(0);
    //transition: all 1s ease;
    //position: fixed;
}

.userdata {
    position: absolute;
    right: 30px;
    top: 15px;
    li {
        display: flex;
    }
    &__label {
        width: 100px;
        color: rgb(163, 173, 178);
    }
    &__content {
        white-space: nowrap;
        padding-left: pxToRem(15);
        color: #003a63;
    }
}


