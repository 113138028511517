
address, .footer__address {
    font-style: normal;
}

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    //height: pxToRem(114);
    width: 100%;
    padding: 28px 0px;
    background: #004b6c;
    margin: 40px 0 0 0 !important;
    color: #fff;
    font-weight: 300;


    a {
        color: #fff;
        &:hover {
            color: #469fd7;
        }
    }
    .address__title {
        font-size: pxToRem(20);
        padding-bottom: pxToRem(10);
    }
}
.footer__navigation {
    margin-left: auto;
    align-self: flex-end;

    ul {
        display: flex;
        justify-content: flex-end;

        gap: pxToRem(20);

    }

}
