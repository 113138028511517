/*** Popup ***/

.shop-modal {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: pxToRem(28);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba($color-black, 0.3);
    backdrop-filter: blur(10px);
}

.shop-modal__frame {
    overflow: auto;
    //width: 60%;
    width: 100%;
    max-width: 1050px;
    max-height: 100%;
    padding: pxToRem(28);
    background: #576E85;
    box-shadow: 0 2px 20px rgba($color-black, 0.3);
}

/*** Warenkorb ***/

.header__shop {
    position: relative;
    display: flex;
    align-items: flex-end;

    a {
        flex: 1 1 auto;
    }

    [data-shop] {
        position: relative;
        display: flex;
        width: 36px;
        height: 36px;
        background-image: inline-svg('shopping-bag', $color-secondary, transparent);
        background-position: center;
        background-size: 20px;
        background-repeat: no-repeat;
        border: 1px solid $color-primary;
        border-radius: 50%;
        transition: all .3s ease;
    }
}

.header__shop .cart--counter {
    position: absolute;
    right: -12px;
    top: -8px;
    display: inline-block;
    color: $color-white;
    background: $color-primary;
    font-size: 12px;
    font-weight: 700;
    padding: 2px 8px;
    border-radius: 500px;
}

[data-cart-index="0"] [data-cart-shift] {
    display: none;
}

@include media-breakpoint-up(lg) {
    .header__shop [data-shop] {
        &:hover, &:focus {
            background-color: $color-secondary;
            background-image: inline-svg('shopping-bag', $color-white, transparent);
            border-color: $color-secondary;
        }
    }
}

@media(max-width: 1350px) {
    .header__shop {
        position: absolute;
        top: 40px;
        right: 66px;
    }

    .js-nav-scrolled02 .header__shop {
        top: 20px;
    }
}

/*** Productvariants ***/

.productvariants {
    padding: pxToRem(96) 0;
    background-color: rgba($color-black, 0.3);

    .grid {
        row-gap: pxToRem(48);
    }
}

.productvariants__headline {
    margin-bottom: pxToRem(12);
    font-size: pxToRem(26);
    color: $color-white;
}

@include media-breakpoint-down(md) {
    .productvariants {
        padding: pxToRem(72) 0;

        &__headline {
            margin-bottom: 0;
        }
    }
}

/*** Modal/Popup ***/

.shop-liveform .shop-booking-section .shop-booking-section__content {
    padding: pxToRem(48);
}

.shop-liveform__item:not(:last-child) {
    margin-bottom: pxToRem(36);
}

.shop-liveform__item:not(:last-child) {
    border-bottom: 1px solid rgba($color-black, 0.1);
}

.shop-liveform__item {
    label {
        display: block;
        margin-bottom: pxToRem(10);
        font-size: pxToRem(15);
        font-weight: 700;
    }
}

.shop-liveform__ticket-salutation .chosen-pseudo,
.shop-liveform__ticket-salutation .chosen-single {
    height: 45px;
    font-size: pxToRem(16);
}

.shop-liveform__ticket-firstname input {
    border-left: none !important;
}

.shop-liveform__ticket-salutation,
.shop-liveform__ticket-firstname,
.shop-liveform__ticket-lastname,
.shop-liveform__ticket-email {
    margin-bottom: 28px;

    input {
        font-size: pxToRem(16);
    }
}

.shop-liveform__ticket-remove {
    text-align: right;
    margin-top: -10px;
    margin-bottom: 15px;
}

.shop-liveform__ticket-remove a {
    font-weight: 700;
    font-size: 14px;
}

.shop-liveform__header {
    display: flex;
    column-gap: pxToRem(20);
    line-height: 1.2;
    margin-bottom: pxToRem(64);
}

.shop-liveform__headline {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    background-color: #36516D;

    span {
        display: inline-flex;
        align-items: center;
        padding: pxToRem(8) pxToRem(18);
        color: $color-white;
        font-size: pxToRem(18);
        font-weight: 700;
        background-color: $color-secondary;
    }
}

.shop-liveform__close {
    display: inline-block;
    width: 36px;
    height: 36px;
    background-color: $color-primary;
    background-image: inline-svg('cross', $color-white);
    background-position: center;
    background-size: 24px;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: all .3s ease;

    &:hover, &:focus {
        background-color: $color-secondary;
    }
}

.shop-liveform__add {
    display: flex;
    align-items: center;

    .button {
        justify-content: initial;
        font-size: pxToRem(18);
        font-weight: 400;
        text-decoration: underline;
        white-space: initial;
        hyphens: auto;
    }
}

.shop-booking__complete {
    .grid {
        justify-content: space-between;
        row-gap: pxToRem(16);
    }

    .button[data-cart-collect] {
        color: $color-secondary;
        background-color: initial;
        border: 1px solid $color-primary;

        &::after {
            background-image: inline-svg('angle-right', $color-secondary, transparent);
        }

        &:hover, &:focus {
            color: $color-white;
            border-color: $color-secondary;

            &::after {
                background-image: inline-svg('angle-right', $color-white, transparent);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .shop-liveform__ticket-firstname {
        padding-right: 14px;
    }

    .shop-liveform__ticket-lastname {
        padding-left: 14px;
    }
}

@include media-breakpoint-down(md) {
    .shop-liveform__ticket-firstname input {
        border-left: 1px solid #D9D9D9 !important;
    }

    .shop-liveform .shop-booking-section .shop-booking-section__content {
        padding: pxToRem(48) pxToRem(20);
    }

    .shop-liveform .shop-booking__complete .shop-booking-section__content {
        padding: pxToRem(20);
    }
}

@include media-breakpoint-down(sm) {
    .shop-booking-section__headline {
        margin-left: 0;
    }
}

/*** Shop-Teaser Overview ***/

.shop-teaser-overview__item, .shop-teaser-detail__item {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $color-primary-light;
    transition: all .3s ease;

    .teaser__facts {
        padding: 0 pxToRem(18);
    }

    &:hover, &:focus {
        background-color: rgba($color-black, 0.3);

        .shop-teaser-overview-item {
            &__label {
                background-color: $color-primary-light;
            }

            &__subline {
                background-color: $color-secondary;
            }

            &__info {
                border-color: #03B1B8;
            }
        }
    }
}

.shop-teaser-overview-item, .shop-teaser-detail-item, .shop-booking-product, .selectedvariant {
    &__label {
        display: flex;
        justify-content: flex-end;
        line-height: 1.2;
        background-color: $color-primary;
        transition: all .3s ease;

        span {
            display: inline-flex;
            align-items: center;
            padding: pxToRem(8) pxToRem(18);
            color: $color-white;
            font-weight: 700;

            background-color: $color-secondary;
        }
    }

    .teaser__facts {
        padding: 0 pxToRem(18);
    }

    &__headline {
        display: block;
        padding: pxToRem(28) pxToRem(18) pxToRem(12);
        font-size: pxToRem(30);
    }

    &__subline {
        display: inline-block;
        padding: pxToRem(8) pxToRem(18);
        color: $color-white;
        font-size: pxToRem(18);
        font-weight: 700;
        background-color: $color-primary;
        transition: all .3s ease;
    }

    &__content {
        padding: pxToRem(28) pxToRem(18) pxToRem(14);
    }

    &__info:not(:first-child) {
        border-top: 1px solid $color-primary;
    }

    &__info {
        padding: pxToRem(20) 0;
        font-size: pxToRem(18);
        font-weight: 700;

        &:last-child {
            border-bottom: 1px solid $color-primary;
        }

        span:first-child {
            display: inline-block;
            min-width: pxToRem(100);
        }
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        row-gap: pxToRem(16);
        padding: pxToRem(14) pxToRem(18) pxToRem(28);
    }

    &__promotion {
        position: relative;
        display: flex;
        column-gap: pxToRem(12);
        align-items: center;
        max-height: pxToRem(38);
        padding: pxToRem(8) pxToRem(16) pxToRem(8) pxToRem(6);
        font-size: pxToRem(18);
        font-weight: 700;
        background-color: $color-white;
        border-radius: 19px;

        &::before {
            content: '';
            display: inline-block;
            width: 28px;
            height: 28px;
            background-image: inline-svg('discount-tag', $color-secondary);
            background-size: 28px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &__price--vat {
        color: $color-white;
    }
}

.shop-product__price {
    display: inline-block;
    flex-direction: column;

    &--new {
        color: $color-secondary;
        font-size: pxToRem(30);
        font-weight: 700;
    }

    &--old {
        color: $color-secondary;
        font-size: pxToRem(17);
        font-weight: 700;
        text-decoration: line-through;
    }
}

/*** Shop-Teaser Detail ***/

.shop-teaser-detail-item {
    &__content {
        padding-top: initial;
    }

    &__info {
        &:first-child {
            border-top: initial;
        }
    }

    &__footer {
        flex-direction: column;
        height: 100%;
    }

    &-footer {
        &__top, &__bottom {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }

        &__top {
            align-items: flex-start;
            row-gap: pxToRem(16);
        }

        &__bottom {
            align-items: flex-end;
            margin-top: pxToRem(16);
        }
    }

    &__notice {
        display: inline-block;
        padding: pxToRem(4) pxToRem(12);
        color: $color-white;
        font-size: pxToRem(15);
        font-weight: 700;
        background-color: #CD3737;
    }
}

/*** Shop Booking ***/

.shop-booking {
    padding-bottom: pxToRem(96);
    background-color: rgba($color-black, 0.3);

    &-inner {
        display: flex;
        flex-direction: column;
        row-gap: pxToRem(96);
    }

    &__product {
        display: flex;
        column-gap: pxToRem(48);
        padding: 0 pxToRem(48) pxToRem(48);
        margin-bottom: pxToRem(48);
        background-color: $color-white;

        p {
            color: $color-white;
        }

        .shop-booking-product__info {
            &:last-child {
                border-bottom: initial;
            }

            &--trainer {
                display: flex;
            }

            .image__outer {
                aspect-ratio: 1 / 1;
                width: 100%;
                max-width: 160px;
                height: auto;
                margin-left: auto;
            }
        }

        .shop-booking-product__label {
            display: flex;
            justify-content: flex-end;
            background-color: $color-primary;
            transition: all .3s ease;

            span {
                display: inline-block;
                padding: pxToRem(8) pxToRem(18);
                color: $color-white;
                font-weight: 700;
                background-color: $color-secondary;
            }
        }

        .shop-booking-product__price-infos {
            height: 100%;
        }

        .shop-booking-product-price-infos__content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: pxToRem(28);
            background-color: $color-secondary;
        }

        .shop-booking-product__price-headline {
            margin-bottom: pxToRem(16);
            font-size: pxToRem(18);
            font-weight: 700;
        }

        .shop-booking-product__promotion {
            margin-top: pxToRem(28);
        }

        .shop-booking-product__promocode {
            margin-top: auto;

            span {
                color: $color-white;
            }
        }
    }

    .shop-product__price {
        p {
            color: $color-white;
        }

        &--new {
            font-size: pxToRem(48);
        }

        &--vat {
            margin-top: pxToRem(16);
            font-size: pxToRem(15);
            font-weight: 700;
        }
    }

    &__participants, &__invoice, &__complete {

        &:not(:last-child) {
            margin-bottom: pxToRem(48);
        }

        .shop-booking-section__headline {
            display: inline-block;
            padding: pxToRem(12) pxToRem(36);
            margin-left: pxToRem(48);
            color: $color-white;
            font-size: pxToRem(18);
            font-weight: 700;
            background-color: $color-primary;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }

        .shop-booking-section__content {
            background-color: $color-white;
            padding: pxToRem(28);
        }
    }

    &__checkout {
        background-color: $color-white;
        padding: pxToRem(48);

        .shop-booking-checkout__content {
            display: flex;
            padding: pxToRem(28);
            background-color: $color-secondary;
        }

        .shop-booking-checkout__price-headline {
            color: $color-white;
            font-size: pxToRem(18);
            font-weight: 700;
        }
    }

    &-checkout__price-info {
        padding-right: pxToRem(48);
        border-right: 1px solid $color-secondary-bg;
    }

    &-checkout__price-additions {
        display: flex;
        flex: 1 1 auto;
        justify-content: space-between;
        align-items: flex-end;
        padding-left: pxToRem(48);
    }

    &-checkout__button {
        margin-top: pxToRem(64);
        min-height: pxToRem(64);

        &::after {
            content: none;
        }
    }
}

/*** Selected Variant ***/

.selectedvariant {
    &__content {
        display: flex;
        flex-wrap: wrap;
        column-gap: pxToRem(28);
        row-gap: pxToRem(28);
        padding: initial;
    }

    &__leftcol {
        display: flex;
        flex-direction: column;
        flex: 1 1 70%;
    }

    ul.selectedvariant__infos {
        column-count: 1;
        margin-bottom: pxToRem(36);
    }

    &__info:not(:first-child) {
        border-color: #BEC7D0;
    }

    &__info {
        &:last-child {
            border-color: #BEC7D0;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        padding: initial;
    }

    &-footer__top {
        display: flex;
        flex-wrap: wrap;
        row-gap: pxToRem(16);
        justify-content: space-between;
        width: 100%;
    }

    &-footer__bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        row-gap: pxToRem(20);
        width: 100%;
        margin-top: pxToRem(36);
    }

    &__notice {
        padding: pxToRem(4) pxToRem(12);
        color: $color-white;
        background-color: #CD3737;
    }

    &__rightcol {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        h5 {
            font-size: pxToRem(18);
        }
    }

    ul.selectedvariant__infos--trainer {
        display: flex;
        flex-direction: column;
        padding: pxToRem(28) pxToRem(16);
        margin-top: pxToRem(20);
        border: 1px solid #BEC7D0;
        column-count: 1;
    }

    &__info--trainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: pxToRem(8);

        .image__outer {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            overflow: hidden;
        }

        &:not(:first-child) {
            margin-top: pxToRem(28);
        }

        span {
            font-weight: 700;
        }
    }
}

/*** Warenkorb ***/

.cart {
    padding-top: pxToRem(96);
    padding-bottom: pxToRem(96);
    background-color: $color-secondary-bg;

    .grid {
        row-gap: pxToRem(72);
    }

    h3 {
        color: $color-white;
        font-size: pxToRem(26);
        margin-bottom: pxToRem(36);
    }
}

.cart--frame {
    padding: pxToRem(20) pxToRem(18);
    background-color: $color-white;

    &:not(:last-child) {
        margin-bottom: pxToRem(60);
    }

    h3 {
        color: $color-secondary;
    }
}

.cart-frame--voucher {
    h4 {
        display: block;
        font-size: pxToRem(18);
    }

    .cart-frame__header {
        position: relative;
        //padding-right: pxToRem(32);
        //
        //&::after {
        //    content: '';
        //    position: absolute;
        //    right: 0;
        //    top: 0;
        //    display: inline-block;
        //    width: 24px;
        //    height: 24px;
        //    background-image: inline-svg('angle-l-down', $color-black, transparent);
        //    background-position: center;
        //    background-size: 24px;
        //    background-repeat: no-repeat;
        //}
    }

    .cart-frame__content {
        margin-top: pxToRem(20);

        input {
            width: 100%;
            border: none;
            box-shadow: 0 2px 6px 0 rgba($color-black, 0.08);
            border-radius: 8px;
        }

        .button {
            width: 100%;
            justify-content: flex-start;
            margin-top: pxToRem(20);
        }
    }
}

.checkout__item {
    margin-bottom: pxToRem(8);
}

.checkout--brutto, .checkout--shipping, .checkout--total {
    display: flex;
    justify-content: space-between;

    .checkout--label {
        hyphens: auto;
    }

    .checkout--value {
        padding-left: pxToRem(8);
        white-space: nowrap;
    }
}

.checkout--vat {
    overflow-wrap: break-word;
}

.checkout--total {
    padding-top: pxToRem(20);
    margin-top: pxToRem(20);
    font-weight: 700;
    border-top: 1px solid $color-background;
}

.checkout__button {
    justify-content: flex-start;
    width: 100%;
    margin-top: pxToRem(20);
}

/*** Cart View ***/

.cart__item {
    background-color: $color-primary-light;

    &:not(:last-child) {
        margin-bottom: pxToRem(60);
    }
}

.cart__label {
    display: flex;
    background-color: $color-primary;

    span {
        display: inline-flex;
        align-items: center;
        padding: pxToRem(8) pxToRem(18);
        color: $color-white;
        font-weight: 700;
        line-height: 1.2;
        background-color: $color-secondary;
    }
}

.cart__content {
    display: flex;
    justify-content: space-between;
    column-gap: pxToRem(20);
    padding: pxToRem(28) pxToRem(18);

    &--left {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &--right {
        display: flex;
        flex-direction: column;
    }
}

.cart-custom {
    display: flex;
    font-weight: 700;

    &__label {
        min-width: pxToRem(140);
    }

    &:not(:first-child) {
        padding-top: pxToRem(16);
        margin-top: pxToRem(16);
        border-top: 1px solid $color-primary;
    }
}

.cart--actions {
    margin-top: auto;
    padding-top: pxToRem(36);
}

.cart-action {
    position: relative;
    padding-left: pxToRem(32);
    font-size: pxToRem(15);

    &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: 0;
        display: inline-block;
        width: 24px;
        height: 24px;
        background-position: center;
        background-size: 24px;
        background-repeat: no-repeat;
    }

    &--remove {
        &::before {
            background-image: inline-svg('trash-bin', $color-primary, transparent);
        }
    }

    &--update {
        &::before {
            background-image: inline-svg('edit', $color-primary, transparent);
        }
    }

    &:not(:first-child) {
        margin-left: pxToRem(25);

        &::after {
            content: '';
            position: absolute;
            top: -3px;
            left: -13px;
            width: 1px;
            height: 26px;
            background-color: #8C9BAB;
        }
    }
}

.cart--quantity {
    margin-bottom: pxToRem(48);

    .bootstrap-touchspin {
        display: flex;
        align-items: center;
        height: pxToRem(48);
        border: 1px solid $color-primary;
        border-radius: 36px;

        & > span {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-color: transparent;

            .button {
                width: 40px;
                height: 40px;
                color: $color-secondary;
                font-weight: 400;
                font-size: pxToRem(20);
                background-color: transparent;
                border-color: transparent;

                &:hover, &:focus {
                    background-color: transparent;
                }
            }

            .button::after {
                content: none;
            }
        }

        .input {
            width: 50px;
            text-align: center;
            font-weight: 700;
            background-color: transparent;
            border: none;
        }
    }
}

.cart--total {
    margin-top: auto;
}

.cart--value {
    text-align: right;
    font-size: pxToRem(22);
    font-weight: 700;
}

.cart--price {
    margin-top: pxToRem(4);
    font-size: pxToRem(15);
    text-align: right;
}

.cart--empty {
    color: $color-white;
}

@include media-breakpoint-down(md) {
    .cart {
        padding-top: pxToRem(72);
        padding-bottom: pxToRem(72);
    }
}

@include media-breakpoint-down(sm) {
    .cart__content {
        flex-direction: column;

        &--left {
            flex: 1 1 100%;
        }

        &--right {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: pxToRem(20);
        }
    }

    .cart--quantity {
        margin-bottom: initial;
    }

    .cart--actions {
        padding-top: initial;
        margin-top: pxToRem(12);
    }
}